import React from "react";
import SectionTitle from "../components/sectiontitle";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

export default function() {
    return (
        <Layout>
            <SEO lang="en" title="Cartography" />
            <section id="art" className="container" style={{ minHeight: "600px" }}>
                <div className="section-title">
                    <SectionTitle title="Cartography" />
                        <p className="text-primary">
                        "Geography and our identification with it, give us a sense of place."
                        </p>

                        <p className="text-primary">Maps are challenging information design 
                        challenges containing many layers of spatial information melded together 
                        into a flat, 2D document. Our philosophy is to use every design tool 
                        available to present geographic information as clearly and elegantly 
                        as possible.
                        </p>


                        <p className="text-primary">Since the early 2000's, we have evolved a unique and 
                        compelling shaded relief map style which has proven popular with hikers, 
                        mountain bikers, runners, and even other map-makers. This style has been 
                        progressively refined as a result of feedback from map users. Art of Geography 
                        maps were the official map at a number of California State Parks.
                        </p>

                        <h5 className="align-left">Process</h5>

                        <p className="text-primary"> 
                        Similar to the discovery phase of a website project, the first step is 
                        understanding who the audience for the map will be. If it will be a hardcopy 
                        map, then the print size is going to have a huge impact on downstream choices. 
                        Depending on the scale of the map, a projection may need to be chosen based 
                        on the map goals and audience. </p> 

                        <p className="text-primary">If the end product is a paper map, mockups are made to show how the 
                        folded map might look. Not every printer can handle every fold option, so 
                        getting the printer locked in will help with the fold options. 
                        </p>

                        <p className="text-primary"> 
                        For most of our map projects, we use a variety of GIS tools to compile a 
                        complete geospatial dataset for the area being mapped, usually including 
                        data from field observations. Recently QGIS has been the GIS tool of choice,
                        but we've used Cartographica and GlobalMapper.  
                        </p>
                        
                        <p className="text-primary"> 
                        Then once the GIS data is complete, it is exported so the final map can
                        be designed and refined in Adobe Illustrator. Inevitably a number of proof and
                        review cycles occur so that the map can be tested by real people. When the 
                        map is ready, it is imported into InDesign so the complete brochure can be 
                        laid out. 
                        </p>

                        <p className="text-primary"> 
                        Then once the GIS data is complete, it is exported so the final map can
                        be designed and refined in Adobe Illustrator. Inevitably a number of review 
                        cycles occur so that the map can be tested by real people. Then it is time 
                        for submitting to the printer and reviewing their proof prints. 
                        </p>

                        <h5 className="align-left">Highlights of Art of Geography cartographic projects</h5>

                        {/* <p className="align-left">
                        <Link to="/portfolio/maps">View portfolio of maps</Link>
                        </p> */}





                        <ul className="body-content-list">
                            <li>Walk There maps -- a collaborative effort with Matthew Hampton @oregonmetro. 
                                This popular guidebook contains 50 walks, each with a map. 

                                "Walk There! maps won the highest honor in the July 2009, 
                                Environmental Science Research Institute (ESRI) International User 
                                Conference. This is the world's largest event dedicated to geographic 
                                information system (GIS) technology. The maps took first place in this 
                                particular category and later went on to win best overall, beating 
                                out all other first-place finishers and prevailing over 2,000 entries." 
                            </li>

                            <li>Three giant wall maps for an athletic club: 
                            one shows all the trails, parks, and recreational opportunities surrounding 
                            San Francisco; while the other two are 27 foot wide wall maps showing all 
                            the trails, parks and recreational opportunities in the south San Francisco 
                            Bay region.
                            </li>

                            <li>A 12 foot wide translucent street map of San 
                            Francisco showing attractions & landmarks, mounted in glass in the lobby 
                            of a major San Francisco hotel. A road map of Mexico and the border areas 
                            of adjoining US States.
                            </li>

                            <li>Official park maps for Big Basin Redwoods State 
                            Park, The Forest of Nisene Marks State Park, Castle Rock State Park, Portola 
                            Redwoods State Park, Wilder Ranch State Park, and Henry Cowell State Park.
                            </li>
                        </ul>
                        <p>&nbsp; </p>
                        <div className="align-left">
                            <a href="/portfolio/maps" className="btn">
                                View map portfolio
                            </a>
                        </div>


                        <p>&nbsp; </p>
                        <p className="text-primary"><i>Maps remind us of where we’ve been and where 
                            we’d still like to go.
                        </i></p>

                </div>
            </section>
        </Layout>
    );
}
